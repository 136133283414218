allcaps = function (main, $) {

    main.gui = main.gui || {};
    main.gui.correction = main.gui.correction || {};

    var g = main.gui.correction;

    g.init = function () {
        // start all croppers
        $('.jsCropper').each(function () {
            startCropper($(this)[0], undefined, $(this).closest('.jsCropperContainer'));
        });

        $('.jsSelectAll').on('click', function () {
            var active =$(this).prop('checked');
            if(active){
                $('input:checkbox:not(.jsSelectAll, .jsIgnoreCheckbox, .jsEmptyValue)').prop('checked', true);

            } else {
                $('input:checkbox:not(.jsSelectAll, .jsIgnoreCheckbox)').prop('checked', false);
            }

            checkSubmitButton();
        });
        //
        $('#jsCorrectionForm input:checkbox:not(.jsSelectAll, .jsIgnoreCheckbox)').change(function () {
            checkSubmitButton();
        });
    };

    function checkSubmitButton() {
        var submitBtn = $('#jsCorrectionForm button:submit');
        submitBtn.addClass('btn-danger').removeClass('btn-default').text(submitBtn.data('zero'));


        if ($("#jsCorrectionForm input:checkbox:checked:not(.jsSelectAll, .jsIgnoreCheckbox)").length > 0) {
            submitBtn.removeClass('btn-danger').addClass('btn-success').text(submitBtn.data('many'))
        }
    }

    function updateCropData(instance, rotation, $cropperContainer) {
        var points = instance.get().points;
        var data = {
            'x1': points[0],
            'y1': points[1],
            'x2': points[2],
            'y2': points[3],
            'rotation': rotation % 360
        };
        $cropperContainer.find('.jsImageCropData').val(JSON.stringify(data));
    }

    function startCropper($cropperCanvasDiv, instance, $cropperContainer) {

        if (instance !== undefined) {
            return instance;
        }

        // init the cropper
        instance = new Croppie($cropperCanvasDiv, {
            viewport: {width: 500, height: 500, type: 'circle'},
            boundary: {width: 510, height: 510},
            enableOrientation: true,
            update: function (croppie) {
                updateCropData(instance, $cropperContainer.data('rotation'), $cropperContainer);
            }
        });


        var instructions = JSON.parse($cropperContainer.find('.jsImageCropData').val());
        instance.bind({
            url: $cropperContainer.find('.jsImageCropUrl').val(),
            points: [
                instructions.x1,
                instructions.y1,
                instructions.x2,
                instructions.y2
            ]
        }).then(function () {
            instance.rotate(instructions.rotation);
            var newRotation = instructions.rotation;
            $cropperContainer.data('rotation', newRotation);
            updateCropData(instance, newRotation, $cropperContainer);
        });

        // init rotate buttons
        $cropperContainer.find('.jsCropperRotate').on('click', function (ev) {
            var degrees = parseInt($(this).data('rotate'));
            var newRotation = parseInt($cropperContainer.find('.jsRotationSlider').val());
            if (isNaN(newRotation)) {
                newRotation = 0;
            }
            newRotation += degrees;
            if (newRotation === 90 || newRotation === 270) {
                if (parseInt($cropperContainer.data('rotation')) > newRotation) {
                    newRotation--;
                } else {
                    newRotation++;
                }
            }
            $cropperContainer.data('rotation', newRotation);
            instance.rotate(newRotation);
            updateCropData(instance, newRotation, $cropperContainer);
        });

        return instance;
    }

    return main;

}(window.allcaps || {}, jQuery);
