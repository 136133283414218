allcaps = function (main, $) {

    main.gui = main.gui || {};
    main.gui.search = main.gui.search || {};

    var g = main.gui.search;


    g.init = function () {
        var producers = new Bloodhound({
            datumTokenizer: Bloodhound.tokenizers.obj.whitespace('title'),
            queryTokenizer: Bloodhound.tokenizers.whitespace,
            identify: function(obj) { return obj.slug; },
            remote: {
                url: main.utils.route('front.query'), //'/'+locale+'/search/%QUERY',
                wildcard: '_QUERY',
                rateLimitBy : 'debounce',
                rateLimitWait : 600,
            }
        });

        $('#search').typeahead({
            hint: true,
            highlight: false,
            minLength: 2,
            classNames: {
                highlight: 'mark',
                menu: 'list-group'
            }
        }, {
            name: 'caps',
            display: 'title',
            limit: 150,
            source: producers,
            templates: {
                notFound : function(object) {
                    toggleSearching(false);
                    return '<div class="list-group-item text-center text-muted">'+ trans('search.autocomplete.no_results').replace(/:searchTerm/g, '<mark>'+escapeHtml(object.query)+'</mark>')+'</div>';
                },
                pending: function(){
                    toggleSearching(true);
                },
                footer: function(object){
                    toggleSearching(false);
                    if(object.suggestions.length >= 10){
                        return '<a href="' + main.utils.route('front.query').replace('_QUERY', escapeHtml(object.query)) + '" class="list-group-item text-center text-muted"><i class="fa fa-search-plus"></i> '+trans('search.autocomplete.more_results')+'</a>';
                    }
                    return '';
                },
                suggestion: function(object){
                    html = '<a href="' + getLinkFromSuggestion(object) +'" class="list-group-item">';

                    // show different badge to indicate where user is going to
                    if(object.caps_count === object.producer_total){
                        html += '<span class="badge"><i class="fa fa-fw fa-industry"></i> '+ object.caps_count +'</span>';
                    } else if(object.caps_count !== 1){
                        html += '<span class="badge">'+ object.caps_count +'</span>';
                    }
                    return  html + object.title + '</a>'
                }
            }
        }).bind('typeahead:select', function(ev, suggestion) {
            window.location = getLinkFromSuggestion(suggestion).replace('&amp;', '&');

            ev.preventDefault();
        }).bind('typeahead:cursorchange', function(ev, suggestion) {
            // set the value of the searchbox to html stripped version
            // don't set it with typehead or it will trigger a search
            if(suggestion){
                $('#search').val(escapeHtml(suggestion.title));
            }
        }).bind('typeahead:close', function(ev) {
            $('#search').val('');
        });

        function escapeHtml(unsafe)
        {
            return unsafe
                .replaceAll(/&/g, "&amp;")
                .replaceAll(/</g, "&lt;")
                .replaceAll(/>/g, "&gt;")
                .replaceAll(/"/g, "&quot;")
                .replaceAll(/'/g, "&#039;")
                .replaceAll(/[^\x00-\xFF]/gi, '');
        }

        function getLinkFromSuggestion(object){
            var url;
            // if there's only 1 result for producer, go to cap directly
            if(object.caps_count === 1){
                url = main.utils.route('front.cap').replace('_ID_', object.first_id);
            } else if(object.caps_count === object.producer_total) {
                // if there are as much results as caps for the producer => go to the producer
                url = main.utils.route('front.producer').replace('_ID_', object.slug);
            } else {
                // go to search page filtered with search results for only this producer
                url = main.utils.route('front.search').replace('_Q_', escapeHtml(object.query) ).replace('_SLUG_', object.slug);
            }
            return url;
        }
    };

    function toggleSearching(searching){
        if(searching){
            $('.jsSearchButton').removeClass('fa-search').addClass('loader').parent().addClass('disabled');
        }else{
            $('.jsSearchButton').addClass('fa-search').removeClass('loader').parent().removeClass('disabled');
        }
    }

    return main;

}(window.allcaps || {}, jQuery);
