allcaps = function (main, $) {

    main.gui = main.gui || {};
    main.gui.cap = main.gui.cap || {};

    var g = main.gui.cap;

    var croppieInstance;
    var croppieInstanceVerso;

    function readFile(input, cropper, $container) {
        if (input.files && input.files[0]) {
            var reader = new FileReader();

            reader.onload = function (e) {
                $container.data('rotation', 0);
                cropper.bind({
                    url: e.target.result
                }).then(function () {

                });
            };
            reader.readAsDataURL(input.files[0]);
        }
        else {
            main.utils.showError("Sorry - something went wrong");
        }
    }

    g.init = function () {
        // if errors are present, open up the correction tab
        if ($('#corrections .has-error').length > 0) {
            $('#jsCorrectionsTab').tab('show');
        }

        g.initImageInput();
        initMyNotes();
        initRectoVerso();
    };

    g.startImageCorrection = function () {
        $('#jsCorrectionsTab').tab('show');
        document.getElementById('image_input').click();
    };

    // init cropper on file change
    g.initImageInput = function () {
        $('#image_input').on('change', function () {
            croppieInstance = startCropper($('#jsCropper')[0], croppieInstance, $('.jsCropperSelect, .jsCropperSelectRecto'),$('.jsCropperContainer'));
            readFile(this, croppieInstance, $('.jsCropperContainer'));
        });
        $('#image_input_verso').on('change', function () {
            croppieInstanceVerso = startCropper($('#jsCropperVerso')[0], croppieInstanceVerso, $('.jsCropperSelect, .jsCropperSelectVerso'), $('.jsCropperVersoContainer'));
            readFile(this, croppieInstanceVerso, $('.jsCropperVersoContainer'));
        });
        initColorCheckboxes();
    };

    function updateCropData(instance, rotation, $cropperContainer) {
        var points = instance.get().points;
        var data = {
            'x1': points[0],
            'y1': points[1],
            'x2': points[2],
            'y2': points[3],
            'rotation': rotation % 360
        };

        $cropperContainer.find('.jsImageCropData').val(JSON.stringify(data));
    }

    function startCropper($cropperCanvasDiv, instance, elementsToHide, $cropperContainer) {

        if (instance !== undefined) {
            return instance;
        }

        // init the cropper
        instance = new Croppie($cropperCanvasDiv, {
            viewport: {width: 440, height: 440, type: 'circle'},
            boundary: {width: 450, height: 450},
            enableOrientation: true,
            update: function (cropDetails) {

                updateCropData(instance, $cropperContainer.data('rotation'), $cropperContainer);
            }
        });

        // show the cropper, hide the upload field
        elementsToHide.toggleClass('hidden', true);
        $cropperContainer.toggleClass('hidden', false);

        // init rotate buttons
        $cropperContainer.find('.jsCropperRotate').on('click', function (ev) {
            var degrees = parseInt($(this).data('rotate'));
            var newRotation = parseInt($cropperContainer.find('.jsRotationSlider').val());
            if (isNaN(newRotation)) {
                newRotation = 0;
            }
            newRotation += degrees;
            if (newRotation === 90 || newRotation === 270) {
                if (parseInt($cropperContainer.data('rotation')) > newRotation) {
                    newRotation--;
                } else {
                    newRotation++;
                }
            }
            $cropperContainer.data('rotation', newRotation);
            instance.rotate(newRotation);
            updateCropData(instance, newRotation, $cropperContainer);
        });

        return instance;
    }

    // init notes on blur/change + ajax save
    function initMyNotes() {
        // show buttons to save the note on focus
        $('#myNotes')
            .on('focus blur', function () {
                if($('.jsNoteButtons').hasClass('collapse')){
                    $('.jsNoteButtons').collapse('show');
                }
            });
    }

    // init recto verso
    function initRectoVerso() {
        // show buttons to save the note on focus
        $('.jsToggleRectoVerso')
            .on('click', function () {

                $('#recto, #verso').hide();
                $('#' +$(this).data('type')).show();

                $('.jsToggleRectoVerso').removeClass('btn-success');
                $(this).addClass('btn-success');
            });
    }

    // handle color checkboxes
    function initColorCheckboxes() {
        // disable/deactivate other colors if polychrome is selected
        $(':checkbox[data-color="polychrome"]')
            .on('change', function () {

                var isActive = $(this).prop('checked');
                $(':checkbox[data-group="'+ $(this).data('group')+'"]').not('[data-color="polychrome"]')
                    .prop('checked', false)
                    .prop('disabled', isActive)
                    // fade text
                    .parent().toggleClass('text-muted', isActive)
                    // fade color circle
                    .find('.color').fadeTo(0, isActive ? 0.5 : 1);
                $(this).prop('disabled', false);
            });

        $(':checkbox[data-color="polychrome"]:checked').trigger('change');
    }

    return main;

}(window.allcaps || {}, jQuery);
